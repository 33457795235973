import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Card,
  CardHeader,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import CloseIcon from "@material-ui/icons/Close"

import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { CheckCircle as ConfirmIcon } from "react-feather"
import { useCart } from "../../../User/UserCart/Cart"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"
import ConfirmationAddress from "./ConfirmationAddress"
import ConfirmationItemCard from "./ConfirmationItemCard"
import ConfirmationTotals from "./ConfirmationTotals"
import GuestOrderCreateAccount from "./GuestOrderCreateAccount"
import moment from "moment"

const GuestOrderConfirmation = ({ classes, setGuestConfirmtion }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const [cartUpdated, setCartUpdated] = useState(false)
  const { emptyCart, updateCartId } = useCart()
  const orderId = state.orderId
  const paymentId = state.paymentData.id
  const [orderShipping, setOrderShipping] = useState("")
  const [orderPayment, setOrderPayment] = useState("")
  const [orderItems, setOrderItems] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [calcs, setCalcs] = useState({
    count: 0,
    subtotal: 0,
    taxAmt: 0,
    discountAmt: 0,
    postageAmt: 0,
    balanceDue: 0,
    orderTotal: 0,
    orderPaymentTotal: 0,
  })
  useEffect(() => {
    if (!cartUpdated) {
      emptyCart()
      updateCartId()
      setCartUpdated(true)
    }
  }, [])

  const { data, loading, error } = useQuery(ORDER_CONFIRMATION_QUERY, {
    variables: {
      orderId: orderId,
    },
    onCompleted: data => {
      const orderData = data.myGuestOrder
      setOrderShipping(orderData.orderShipping[0])
      setOrderPayment(orderData.orderPayment[0])
      setOrderItems(orderData.orderWithItem)
      const fullName =
        orderData.customer.firstName + " " + orderData.customer.lastName
      setCustomerName(fullName)
      setOrderDate(orderData.orderDate)
      setCalcs(calcs => ({
        ...calcs,
        count: orderData.orderWithItem.length,
        subtotal: orderData.subtotal,
        taxAmt: orderData.taxAmt,
        discountAmt: orderData.discountAmt,
        postageAmt: orderData.postageAmt,
        balanceDue: orderData.balanceDue,
        orderTotal: orderData.orderTotal,
        orderPaymentTotal: orderData.orderPaymentTotal,
      }))
    },
  })

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xxs={12} xs={12} sm={12} md={6}>
          <Card square variant="outlined" className={classes.mainCard}>
            <CardHeader
              avatar={
                <SvgIcon>
                  <ConfirmIcon />
                </SvgIcon>
              }
              title={
                <Typography className={classes.title}>
                  Order Confirmation
                </Typography>
              }
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="body1" className={classes.bold}>
                  {customerName}
                </Typography>
                <br />
                <Typography component="body2">
                  Order Date: {moment(orderDate).format("MMMM Do YYYY, h:mm a")}
                </Typography>
                <br />
                <Typography component="body2">Order ID: {orderId}</Typography>
                <br />
                <Typography component="body2">
                  Payment ID: {paymentId}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ConfirmationAddress
                  orderPayment={orderPayment}
                  orderShipping={orderShipping}
                />
              </Grid>
              <Grid item xs={12}>
                <>
                  <Typography className={classes.blackTitle}>
                    Order Items
                  </Typography>
                  {orderItems &&
                    orderItems.map(selectedItem => (
                      <ConfirmationItemCard
                        selectedItem={selectedItem}
                        lineItemQty={orderItems.length}
                      />
                    ))}
                </>
              </Grid>
              <Grid item xs={12}>
                <ConfirmationTotals calcs={calcs} />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Please check product web pages for lead times. We will notify
                  you when your order ships. Please create an account with the
                  form on this page if you'd like to follow your order's
                  progress.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xxs={12} xs={12} sm={12} md={6}>
          <GuestOrderCreateAccount orderId={orderId} />
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  blackTitle: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: "bold",
  },
})

const ORDER_CONFIRMATION_QUERY = gql`
  query($orderId: Int) {
    myGuestOrder(orderId: $orderId) {
      id
      orderDate
      businessUnit {
        id
        name
        homepage
        primaryEmail
      }
      customer {
        uid
        firstName
        lastName
      }
      status {
        id
        orderStatus
      }
      subtotal
      taxAmt
      discountAmt
      postageAmt
      orderTotal
      orderPaymentTotal
      balanceDue
      balanceDueDate
      orderWithItem {
        id
        qty
        priceEach
        customFields
        item {
          name
          category {
            id
            name
          }
          isPrivate
          pricingModel {
            id
            name
          }
          itemPrice {
            id
            price
          }
          itemImage {
            id
            thumbnailUrl
          }
        }
      }
      orderPayment {
        id
        totalPaid
        billingAddress {
          id
          thirdPartyAddress
          address {
            uid
            region {
              name
            }
            isOrg
            category {
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            city
            zone
            postalCode
          }
          name
        }
      }
      orderShipping {
        id
        shipTo {
          id
          name
          thirdPartyAddress
          address {
            uid
            region {
              name
            }
            isOrg
            category {
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            city
            zone
            postalCode
          }
        }
      }
    }
  }
`
export default withStyles(styles)(GuestOrderConfirmation)
