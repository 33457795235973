import React, { useContext } from "react"
import { Box, ButtonBase, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"

import {
  regions,
  allRegions,
  usaStates,
  canadaProvinces,
} from "../../../DropIn/Lists/geography"

const GuestOrderSummaryShipping = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const postalCode = state.spc
  // NOTE: Only handle one shipping address for now.
  const shipTo = state.guestShippingAddress ? state.guestShippingAddress : ""
  const fullName =
    shipTo && shipTo.firstName && shipTo.lastName
      ? shipTo.firstName + " " + shipTo.lastName
      : ""
  const region =
    shipTo && shipTo.regionId
      ? regions.find(r => r.id == shipTo.regionId)
      : null
  const regionName = region ? region.name : ""
  const handleConfirmShipping = () => {
    dispatch({ type: "TOGGLE_EDIT_MODE" })
    dispatch({ type: "SET_ACTIVE_STEP", payload: 1 })
  }
  return (
    <Box>
      <ButtonBase
        focusRipple
        className={classes.menuButton}
        // focusVisibleClassName={classes.focusVisible}
        style={{
          // width: "100%",
          display: "block",
          padding: 3,
        }}
        onClick={handleConfirmShipping}
      >
        <Typography className={classes.title}>
          {shipTo ? "Ship To" : ""}
        </Typography>
      </ButtonBase>

      <Box mt={1} pl={1}>
        <Typography variant="caption">
          {shipTo ? (
            <>
              {/* {JSON.stringify(shipTo)} */}
              {fullName ? fullName : ""}
              {fullName && <br />}
              {shipTo.attention && `attn: ${shipTo.attention}`}
              {shipTo.attention && <br />}
              {shipTo.addressLine1 && shipTo.addressLine1}
              {shipTo.addressLine1 && <br />}
              {shipTo.addressLine2 && shipTo.addressLine2}
              {shipTo.addressLine2 && <br />}
              {shipTo.addressLine3 && shipTo.addressLine3}
              {shipTo.addressLine3 && <br />}
              {shipTo.city ? shipTo.city : ""}, {shipTo.zone ? shipTo.zone : ""}{" "}
              {shipTo.postalCode ? shipTo.postalCode : ""} <br />
              {regionName ? regionName : ""}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Box>
    </Box>
  )
}

const styles = theme => ({
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.link,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
        color: theme.palette.link,
      },
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})

export default withStyles(styles)(GuestOrderSummaryShipping)
