import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import GuestStripePaymentForm from "./GuestStripePaymentForm"
const GuestPaymentForm = ({ classes }) => {
  return (
    <>
      <GuestStripePaymentForm />
    </>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(GuestPaymentForm)
