import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  regions,
  allRegions,
  usaStates,
  canadaProvinces,
} from "../../../DropIn/Lists/geography"

const ConfirmationAddress = ({ classes, orderPayment, orderShipping }) => {
  const shipTo = orderShipping.shipTo
  const billTo = orderPayment.billingAddress
  //   const fullName =
  //     shipTo && shipTo.firstName && shipTo.lastName
  //       ? shipTo.firstName + " " + shipTo.lastName
  //       : ""
  const region =
    shipTo && shipTo.address && shipTo.address.regionId
      ? regions.find(r => r.id == shipTo.address.regionId)
      : null
  const regionName = region ? region.name : ""
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title}>Ship To:</Typography>
          {shipTo ? (
            <>
              <Box mt={1} pl={1}>
                <Typography variant="caption">
                  {shipTo && shipTo.name ? shipTo.name : ""}
                  {shipTo.name && <br />}
                  {shipTo.address.attention &&
                    `attn: ${shipTo.address.attention}`}
                  {shipTo.address.attention && <br />}
                  {shipTo.address.addressLine1 && shipTo.address.addressLine1}
                  {shipTo.address.addressLine1 && <br />}
                  {shipTo.address.addressLine2 && shipTo.address.addressLine2}
                  {shipTo.address.addressLine2 && <br />}
                  {shipTo.address.addressLine3 && shipTo.address.addressLine3}
                  {shipTo.address.addressLine3 && <br />}
                  {shipTo.address.city ? shipTo.address.city : ""},{" "}
                  {shipTo.address.zone ? shipTo.address.zone : ""}{" "}
                  {shipTo.address.postalCode ? shipTo.address.postalCode : ""}{" "}
                  <br />
                  {regionName ? regionName : ""}
                </Typography>
              </Box>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title}>Billing:</Typography>

          {billTo ? (
            <>
              <Box mt={1} pl={1}>
                <Typography variant="caption">
                  {billTo && billTo.name ? billTo.name : ""}
                  {billTo.name && <br />}
                  {billTo.address.attention &&
                    `attn: ${billTo.address.attention}`}
                  {billTo.address.attention && <br />}
                  {billTo.address.addressLine1 && billTo.address.addressLine1}
                  {billTo.address.addressLine1 && <br />}
                  {billTo.address.addressLine2 && billTo.address.addressLine2}
                  {billTo.address.addressLine2 && <br />}
                  {billTo.address.addressLine3 && billTo.address.addressLine3}
                  {billTo.address.addressLine3 && <br />}
                  {billTo.address.city ? billTo.address.city : ""},{" "}
                  {billTo.address.zone ? billTo.address.zone : ""}{" "}
                  {billTo.address.postalCode ? shipTo.address.postalCode : ""}{" "}
                  <br />
                  {regionName ? regionName : ""}
                </Typography>
              </Box>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})
export default withStyles(styles)(ConfirmationAddress)
