import React, { useState } from "react"

export const useAddressForm = callback => {
  const [errors, setErrors] = useState({})
  const [updated, setUpdated] = useState(true)
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    isOrg: false,
    regionId: 1,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    zone: "",
    city: "",
    postalCode: "",
    attention: "",
    isFriend: false,
  })

  const handleSubmit = event => {
    if (event) event.preventDefault()
    callback()
    setUpdated(true)
  }

  const handleCheck = event => {
    event.persist()
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.checked,
    }))
  }

  const handleChange = event => {
    event.persist()
    setUpdated(false)
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  return {
    handleCheck,
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
    updated,
    setUpdated,
  }
}
