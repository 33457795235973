import React, { useState, useContext } from "react"
import {
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { UserPlus as AddUserIcon } from "react-feather"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"
import SignUpGuest from "../../../Sign/SignUpGuest"

const GuestOrderCreateAccount = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const { sbuId } = useSiteMetadata()
  const [signedUp, setSignedUp] = useState(false)
  const phoneNumber = state.guestPhone
  const email = state.guestEmail
  const guid = state.guid
  const orderId = state.orderId

  return (
    <>
      <Card square variant="outlined" className={classes.mainCard}>
        <CardHeader
          avatar={
            <SvgIcon>
              <AddUserIcon />
            </SvgIcon>
          }
          title={
            <Typography className={classes.title}>
              Create your Account
            </Typography>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h5">
              {signedUp
                ? "Here are a few great reasons why you should open an account today:"
                : "Thanks for signing up! Once confirmed, you'll have access to these great benefits and more!"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="View and track your order status" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Browse and order your exclusive, customized and private items" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Communicate directly with staff via our messaging app" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="View your order history" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Update your communications settings" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Check, approve, or request changes to samples and proofs" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <SignUpGuest signedUp={signedUp} setSignedUp={setSignedUp} />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  more: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
})

export default withStyles(styles)(GuestOrderCreateAccount)
