import React, { Fragment } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import withStyles from "@material-ui/core/styles/withStyles"

import { Link } from "gatsby"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}
function isCharacterALetter(char) {
  return /[a-zA-Z]/.test(char)
}
const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const GuestSummaryOrderItemCard = ({ classes, item, items }) => {
  const id = item.id
  const qty = item.quantity
  const price = item.price
  const itemTotal = qty * price

  const customFields = []

  const { data, loading, error } = useQuery(CART_ITEM_QUERY, {
    variables: {
      id: id,
    },
    onCompleted: data => {
      console.log(data)
    },
  })

  if (item.customFields) {
    for (const [key, value] of Object.entries(item.customFields)) {
      let format = (
        <Fragment key={key}>
          {isCharacterALetter(key.charAt(0))
            ? capitalize(
                key
                  .replace(/([a-z\d])([A-Z])/g, "$1" + " " + "$2")
                  .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + " " + "$2")
                  .toLowerCase()
              )
            : key}
          : {value}
          <br />
        </Fragment>
      )
      customFields.push(format)
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const selectedItem = data.item
  const priceSet = data.item.itemPrice
  const category = data.item.category.name
  const isPrivate = data.item.isPrivate
  const thumbnail = data.item.featuredImageThumbnailUrl
  const categoryId = data.item.category && data.item.category.id
  return (
    <>
      <Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={3} sm={2}>
            <Avatar
              variant="rounded"
              src={thumbnail}
              className={classes.thumbnail}
            />
          </Grid>
          <Grid item xs={9} sm={6}>
            <Box>
              {isPrivate ? (
                <Link
                  style={{ textDecoration: "none" }}
                  className={classes.link}
                  variant="body2"
                  to={`/my/items/${selectedItem.id}`}
                  state={{ id: `${selectedItem.id}` }}
                >
                  <Typography>{selectedItem.name}</Typography>
                </Link>
              ) : (
                <Typography>{selectedItem.name}</Typography>
              )}
              <Typography>{selectedItem.category.name}</Typography>
              {isPrivate && (
                <Typography variant="caption">Custom Private Item</Typography>
              )}
              <Typography>{customFields ? customFields : ""}</Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={8}>
              {qty} pcs
            </Grid>
            <Grid item xs={4}>
              ${financial(price)} ea
            </Grid>
            {/* {oi.taxAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Tax
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.taxAmt)}
                </Grid>
              </>
            )}
            {oi.discountAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Discount
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.discountAmt)}
                </Grid>
              </>
            )} */}
            {/* {oi.itemDiscount ||
              (oi.itemTax && (
                <>
                  <Grid item xs={8}>
                    Item Subtotal
                  </Grid>
                  <Grid item xs={4}>
                    ${financial(oi.itemSub)}
                  </Grid>
                </>
              ))}
            {oi.itemDiscount && (
              <>
                <Grid item xs={8}>
                  Item Discount
                </Grid>
                <Grid item xs={4}>
                  ${financial(oi.itemDiscount)}
                </Grid>
              </>
            )} */}

            {/* {oi.itemTax && (
              <>
                <Grid item xs={8}>
                  Item Tax
                </Grid>
                <Grid item xs={4}>
                  ${financial(oi.itemTax)}
                </Grid>
              </>
            )} */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={8}>
              Item Total
            </Grid>
            <Grid item xs={4}>
              <strong>${financial(itemTotal)}</strong>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {items.length > 1 && <Divider />}
    </>
  )
}

const CART_ITEM_QUERY = gql`
  query($id: Int) {
    item(id: $id) {
      id
      name
      isPrivate
      category {
        id
        name
      }
      pricingModel {
        id
        name
      }
      customItemField {
        id
        name
        description
        isActive
      }
      featuredImageThumbnailUrl
      specItem {
        unitWeightEach
        weightUnits
      }
      itemPrice {
        id
        minQuantity
        price
      }
    }
  }
`

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 4,
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  rounded: {},
  thumbnail: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
    fontWeight: "bold",
  },
})

export default withStyles(styles)(GuestSummaryOrderItemCard)
