import React, { useContext, useEffect } from "react"
import { Box, ButtonBase, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"
import {
  regions,
  allRegions,
  usaStates,
  canadaProvinces,
} from "../../../DropIn/Lists/geography"

const GuestOrderSummaryBilling = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const billingAddress = state.guestBillingAddress
    ? state.guestBillingAddress
    : ""
  const fullName =
    billingAddress && billingAddress.firstName && billingAddress.lastName
      ? billingAddress.firstName + " " + billingAddress.lastName
      : ""
  const region =
    billingAddress && billingAddress.regionId
      ? regions.find(r => r.id == billingAddress.regionId)
      : null
  const regionName = region ? region.name : ""
  const handleConfirmBilling = () => {
    dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "billing" })
    dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
    if (!state.showAddressComponent) {
      dispatch({ type: "TOGGLE_ADDRESS_COMPONENT" })
    }
  }
  useEffect(() => {
    console.log("from order summary")
  }, [])

  return (
    <Box>
      <ButtonBase
        focusRipple
        className={classes.menuButton}
        style={{
          display: "block",
          padding: 3,
        }}
        onClick={handleConfirmBilling}
      >
        <Typography className={classes.title}>
          {billingAddress ? "Billing Address" : ""}
        </Typography>
      </ButtonBase>

      {state.orderBilling.length > 1 || state.multiplePayments === true ? (
        <Typography>
          Multiple Payments
          <br />
          See below for details
        </Typography>
      ) : (
        <Box mt={1} pl={1}>
          <Typography variant="caption">
            {billingAddress ? (
              <>
                {/* {JSON.stringify(billingAddress)} */}
                {fullName ? fullName : ""}
                {fullName && <br />}
                {billingAddress.attention &&
                  `attn: ${billingAddress.attention}`}
                {billingAddress.attention && <br />}
                {billingAddress.addressLine1 && billingAddress.addressLine1}
                {billingAddress.addressLine1 && <br />}
                {billingAddress.addressLine2 && billingAddress.addressLine2}
                {billingAddress.addressLine2 && <br />}
                {billingAddress.addressLine3 && billingAddress.addressLine3}
                {billingAddress.addressLine3 && <br />}
                {billingAddress.city ? billingAddress.city : ""},{" "}
                {billingAddress.zone ? billingAddress.zone : ""}{" "}
                {billingAddress.postalCode ? billingAddress.postalCode : ""}{" "}
                <br />
                {regionName ? regionName : ""}
              </>
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const styles = theme => ({
  root: {},
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.link,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
        color: theme.palette.link,
      },
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})
export default withStyles(styles)(GuestOrderSummaryBilling)
