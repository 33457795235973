import React, { useState, useContext, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
// import {CardElement, Elements, useElements, useStripe} from '../../src';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"

import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"
import { useCart } from "../../../User/UserCart/Cart"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useAddressForm } from "../../../utils/hooks/useAddressForm"
const useStyles = makeStyles(theme => ({
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.background.highlight,
  },
}))
const stripePromise = loadStripe("pk_test_kkkvfWvmHopjrqS9DdGkfPZF")

const CheckoutForm = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(CheckoutContext)
  const sameAddress = state.sameAddress
  const billingAddress = state.guestBillingAddress
  const billingAddressId =
    billingAddress && billingAddress.id ? billingAddress.id : null
  const shippingAddress = state.guestShippingAddress
  const shippingAddressId = state.shippingAddressId
  const [addressCategoryId, setAddressCategoryId] = useState(1)
  const cartStorage = JSON.parse(localStorage.getItem("ie-cart"))
  const [intent, setIntent] = useState("")
  const [clientSecret, setClientSecret] = useState("")
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [stripePaymentId, setStripePaymentId] = useState(null)

  const stripe = useStripe()
  const elements = useElements()
  const guestEmail = state.guestEmail
  const guestPhone = state.guestPhone
  const billingName =
    billingAddress && billingAddress.firstName && billingAddress.lastName
      ? billingAddress.firstName + " " + billingAddress.lastName
      : ""
  const cardOptions = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#3A3B3C",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#9E9E9E" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  }
  useEffect(() => {
    if (sameAddress) {
      setAddressCategoryId(3)
    }
  }, [])
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }
  const { data, loading } = useQuery(MY_STRIPE_PAYMENT_QUERY, {
    variables: {
      cartId: cartStorage.id,
      shippingAddressId: shippingAddressId,
    },
    onCompleted: data => {
      console.log(data)
      let intent = JSON.parse(data.myStripePayment.intent)
      console.log("logging intent")
      console.log(intent)
      setStripePaymentId(data.myStripePayment.id)
      setIntent(intent)
      setClientSecret(intent.client_secret)
      console.log(`client secret is: ${intent.client_secret}`)
      console.log(shippingAddressId)
    },
  })
  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)
    // console.log(billingAddress)
    // console.log(state.billingDetails)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingName ? billingName : "anon",
          // address: {
          //   line1: billingAddress.addressLine1
          //     ? billingAddress.addressLine1
          //     : "",
          //   line2: billingAddress.addressLine2
          //     ? billingAddress.addressLine2
          //     : "",
          //   city: billingAddress.city ? billingAddress.city : "",
          //   postal_code: billingAddress.postalCode
          //     ? billingAddress.postalCode
          //     : "",
          //   state: billingAddress.zone ? billingAddress.zone : "",
          // },
          email: guestEmail,
          phone: guestPhone,
        },
      },
    })

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      // Create order mutation, properly close cart in backend, return with new cart, email receipt, start the fulfillment flow.
      /// updateCartId works, but it is an incomplete solution.
      console.log(payload)
      setSucceeded(true)
      setError(null)
      setProcessing(false)

      console.log("success. now create new order and cart")

      // emptyCart()
      // updateCartId()
      !paymentComplete && handleCompletePayment()

      console.log("payment completed")
    }
  }

  const [handleCompletePayment] = useMutation(
    COMPLETE_GUEST_STRIPE_PAYMENT_MUTATION,
    {
      variables: {
        stripePaymentId: stripePaymentId,
        succeeded: succeeded,
        sameBillingShipping: state.sameAddress,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        emailAddress: guestEmail,
        phoneNumber: guestPhone,
        isOrg: billingAddress.isOrg,
        regionId: billingAddress.regionId,
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        addressLine3: billingAddress.addressLine3,
        zone: billingAddress.zone,
        city: billingAddress.city,
        postalCode: billingAddress.postalCode,
        thirdPartyAddress: false,
        attention: billingAddress.attention,
        addressCategoryId: addressCategoryId,
        shippingAddressId: shippingAddressId,
        // billingAddressId: billingAddressId,
      },
      onCompleted: data => {
        console.log("payment and mutation completed. New order id:")
        const paymentData = data.completeGuestStripePayment
        console.log(paymentData)
        const newOrderId = paymentData.orderId
        dispatch({ type: "SET_ORDER", payload: paymentData.order })
        dispatch({ type: "SET_ORDER_ID", payload: newOrderId })
        dispatch({ type: "SET_PAYMENT_DATA", payload: paymentData.payment })
        setClientSecret("")
        setIntent("")
        handleNextPage()
      },
      onError: error => {
        console.log(error)
      },
    }
  )
  const handleNextPage = () => {
    setPaymentComplete(true)
    dispatch({ type: "SET_ACTIVE_STEP", payload: 4 })
  }

  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Card variant="outlined" className={classes.mainCard}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xxs={12} xs={12} sm={9}>
            <CardElement
              id="card-element"
              options={cardOptions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xxs={12} xs={12} sm={3}>
            {processing ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                size="small"
                disabled={processing || disabled || succeeded}
                id="submit"
              >
                Pay
              </Button>
            )}
          </Grid>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
        </Grid>
      </form>
    </Card>
  )
}

const MY_STRIPE_PAYMENT_QUERY = gql`
  query($cartId: String, $shippingAddressId: Int) {
    myStripePayment(cartId: $cartId, shippingAddressId: $shippingAddressId) {
      id
      customer {
        uid
        firstName
        lastName
      }
      cart {
        id
        cartId
        guestCart
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        businessUnit {
          id
          name
        }
        cartOpen
        expirationDate
        shippingPostalCode
        cartPrices
      }
      intent
      order {
        id
      }
    }
  }
`

const COMPLETE_GUEST_STRIPE_PAYMENT_MUTATION = gql`
  mutation(
    $stripePaymentId: Int
    $succeeded: Boolean
    $sameBillingShipping: Boolean
    $firstName: String
    $lastName: String
    $emailAddress: String
    $phoneNumber: String
    $isOrg: Boolean
    $regionId: Int
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
    $thirdPartyAddress: Boolean
    $attention: String
    $addressCategoryId: Int
    $shippingAddressId: Int
  ) {
    completeGuestStripePayment(
      stripePaymentId: $stripePaymentId
      succeeded: $succeeded
      sameBillingShipping: $sameBillingShipping
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
      isOrg: $isOrg
      regionId: $regionId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      zone: $zone
      city: $city
      postalCode: $postalCode
      thirdPartyAddress: $thirdPartyAddress
      attention: $attention
      addressCategoryId: $addressCategoryId
      shippingAddressId: $shippingAddressId
    ) {
      stripePayment {
        id
        order {
          id
        }
        customer {
          uid
          firstName
          lastName
        }
      }
      payment {
        id
      }
      message
      orderId
      order {
        id
        cartId
      }
    }
  }
`
const GuestStripePaymentForm = () => (
  <>
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  </>
)
export default GuestStripePaymentForm
