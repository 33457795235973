import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import clsx from "clsx"
import {
  Box,
  ButtonBase,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  NoSsr,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"

import {
  CreditCard as CreditCardIcon,
  Truck as ShippingIcon,
} from "react-feather"

import BigBlackButton from "../../DropIn/BigBlackButton"
import Loading from "../../utils/Loading"
import Error from "../../utils/Error"

import {
  regions,
  allRegions,
  usaStates,
  canadaProvinces,
} from "../../DropIn/Lists/geography"

import CheckoutContext from "../../User/UserCart/Checkout/context/CheckoutContext"
import MuiPhoneNumber from "mui-phone-input-ssr"

import { useAddressForm } from "../../utils/hooks/useAddressForm"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

const phoneTypes = [
  { id: 1, name: "Mobile" },
  { id: 2, name: "Office" },
  { id: 3, name: "Home" },
  { id: 4, name: "App" },
]

const addressTypes = [
  { id: 1, name: "Billing" },
  { id: 2, name: "Shipping" },
  { id: 3, name: "Billing and Shipping" },
]

const GuestInfo = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleChange,
    handleCheck,
    handleSubmit,
  } = useAddressForm(setAddress)

  const { sbuId } = useSiteMetadata()

  const editMode = state.editMode
  const editValues = state.guestShippingAddress
  const sameAddress = state.sameAddress
  const [addressTypeId, setAddressTypeId] = useState(3)
  const [phone, setPhone] = useState("")
  const [phoneType, setPhoneType] = useState(1)
  const [email, setEmail] = useState("")
  const [regionSetting, setRegionSetting] = useState("USA")
  const [findMoreRegions, setFindMoreRegions] = useState(false)
  const [billingAndShipping, setBillingAndShipping] = useState(true)
  const [warning, setWarning] = useState("")
  // const cart = JSON.parse(localStorage.getItem("ie-cart"))
  // const cartId = cart.id

  const [submitting, setSubmitting] = useState(false)

  const isBrowser = () => typeof window !== "undefined"
  const [cart, setCart] = useState("")
  const [cartId, setCartId] = useState("")
  useEffect(() => {
    isBrowser && setCart(JSON.parse(window.localStorage.getItem("ie-cart")))
  }, [])
  useEffect(() => {
    if (cart) {
      setCartId(cart.id)
    }
  }, [cart])
  useEffect(() => {
    if (editMode) {
      setValues(editValues)
      setPhone(state.guestPhone)
      setEmail(state.guestEmail)
    }
  }, [])

  const [handleMutation, { loading, data }] = useMutation(
    START_GUEST_ORDER_MUTATION,
    {
      variables: {
        sbuId: sbuId,
        cartId: cartId,
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: state.guestEmail,
        phoneNumber: phone,
        isOrg: values.isOrg,
        regionId: values.regionId,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        addressLine3: values.addressLine3,
        zone: values.zone,
        city: values.city,
        postalCode: values.postalCode,
        thirdPartyAddress: values.isFriend,
        attention: values.attention,
        addressCategoryId: addressTypeId,
        phoneTypeId: phoneType,
      },
      onCompleted: data => {
        console.log(data)
        const shippingAddressId = data.startGuestOrder.shippingAddressId
        const guid = data.startGuestOrder.person.uid

        dispatch({
          type: "SET_SHIPPING_ADDRESS_ID",
          payload: shippingAddressId,
        })
        dispatch({
          type: "SET_GUID",
          payload: guid,
        })
        // const newIntent = data.startGuestOrder.stripePayment.intent
        // console.log(`Stripe intent is ${newIntent}`)
        handleNextStep()
      },
    }
  )

  function setAddress() {
    dispatch({ type: "SET_GUEST_PHONE", payload: phone })
    // dispatch({ type: "SET_GUEST_EMAIL", payload: email })
    dispatch({ type: "SET_SHIPPING_POSTAL_CODE", payload: values.postalCode })
    if (sameAddress) {
      dispatch({ type: "SET_GUEST_BILLING_ADDRESS", payload: values })
      dispatch({ type: "SET_GUEST_SHIPPING_ADDRESS", payload: values })
    } else {
      dispatch({ type: "SET_GUEST_SHIPPING_ADDRESS", payload: values })
      setAddressTypeId(2)
    }
    // if (addressTypeId === 3) {
    //   dispatch({ type: "SET_GUEST_BILLING_ADDRESS", payload: values })
    //   dispatch({ type: "SET_GUEST_SHIPPING_ADDRESS", payload: values })
    // }
    // if (addressTypeId === 2) {
    //   dispatch({ type: "SET_GUEST_SHIPPING_ADDRESS", payload: values })
    // }
    // if (addressTypeId === 1) {
    //   dispatch({ type: "SET_GUEST_BILLING_ADDRESS", payload: values })
    // }
    handleMutation()
  }

  const handleNextStep = () => {
    if (editMode) {
      dispatch({ type: "TOGGLE_EDIT_MODE" })
    }
    dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
  }

  function handlePhoneChange(value) {
    setPhone(value)
    dispatch({ type: "SET_GUEST_PHONE", payload: value })
  }

  useEffect(() => {
    console.log(values.region)
    setRegionSetting("USA")
    if (values.isInternational) {
      if (values.region == 3) setFindMoreRegions(true)
      values.region == 1
        ? setRegionSetting("USA")
        : values.region == 2
        ? setRegionSetting("Canada")
        : setRegionSetting("other")
    }
  }, [values.region])

  const handleChangeAddressType = event => {
    dispatch({ type: "TOGGLE_SAME_BILLING_SHIPPING_ADDRESS" })
    console.log(sameAddress)
  }

  const checkFields = () => {
    if (values.firstName === "") {
      setWarning("First name is required")
      return false
    } else if (values.lastName === "") {
      setWarning("Last name is required")
      return false
    } else if (state.guestmail === "") {
      setWarning("Email is required")
      return false
    } else if (phone === "") {
      setWarning("Phone is required.")
      return false
    } else if (values.addressLine1 === "") {
      setWarning("Address is required.")
      return false
    } else if (values.zone === "") {
      setWarning("State/Zone is required.")
      return false
    } else if (values.city === "") {
      setWarning("City is required.")
      return false
    } else if (values.postalCode === "") {
      setWarning("Postal Code is required.")
      return false
    } else {
      setWarning("")
      return true
    }
  }

  // const validate = () => {
  //   checkFields() && handleSubmit()
  // }

  return (
    <div className={classes.root}>
      <Card square variant="outlined" className={classes.mainCard}>
        <CardHeader
          action={
            <Typography className={classes.helperText}>
              Have an Account?
              <Link className={classes.link} to="/my/cart">
                Sign In
              </Link>
            </Typography>
          }
        />
        <CardHeader
          avatar={
            addressTypeId === 1 ? (
              <SvgIcon>
                <CreditCardIcon />
              </SvgIcon>
            ) : (
              <SvgIcon>
                <ShippingIcon />
              </SvgIcon>
            )
          }
          title={
            <Typography className={classes.title}>
              {editMode && "Edit & Update "}
              {addressTypeId === 1 ? "Billing Address" : "Shipping Address"}
            </Typography>
          }
        />
        <Box className={classes.content}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xxs={12} xs={12}>
                <Typography className={classes.title}>Your Name</Typography>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    // helperText="(required)"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <FormHelperText id="my-helper-text">
                    (required)
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    variant="outlined"
                    helperText="(required)"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isOrg || false}
                      onChange={handleCheck}
                      name="isOrg"
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={classes.option}>
                      This order is for an organization or business
                    </Typography>
                  }
                />
              </Grid>
              {values.isOrg && (
                <Grid item xxs={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      margin="dense"
                      size="small"
                      label="Organization"
                      id="org"
                      variant="outlined"
                      name="org"
                      value={values.org || ""}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xxs={12} xs={12}>
                <Typography className={classes.title}>Email Address</Typography>
              </Grid>
              <Grid item xxs={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Email Address"
                    id="email"
                    type="email"
                    variant="outlined"
                    name="email"
                    value={state.guestEmail}
                    onChange={event =>
                      dispatch({
                        type: "SET_GUEST_EMAIL",
                        payload: event.target.value,
                      })
                    }
                    // onChange={event => setEmail(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12}>
                <Typography className={classes.title}>
                  Shipping Information
                </Typography>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                {/* <Typography>Ship To</Typography> */}

                <FormControlLabel
                  className={classes.option}
                  control={
                    <Checkbox
                      checked={values.internationalAddress || false}
                      onChange={handleCheck}
                      name="isInternational"
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={classes.option}>
                      Shiping address is outside the USA
                    </Typography>
                  }
                />
                {/* For now, require a login and we will send a ship quote. Checkbox will toggle international form. */}
              </Grid>

              {values.isInternational && (
                <Grid item xxs={12} xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      margin="dense"
                      size="small"
                      select
                      variant="outlined"
                      className={findMoreRegions ? classes.hide : classes.show}
                      helperText="(required)"
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                    >
                      {regions.map(r => (
                        <option key={r.id} value={r.id}>
                          {r.name}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      margin="dense"
                      size="small"
                      fullWidth
                      select
                      label="More Regions"
                      className={findMoreRegions ? classes.show : classes.hide}
                      helperText="(required)"
                      value={values.region}
                      name="region"
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {allRegions.map(r => (
                        <option key={r.id} value={r.id}>
                          {r.name}
                        </option>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              )}
              <Grid item xxs={12} xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isFriend || false}
                      onChange={handleCheck}
                      name="isFriend"
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={classes.option}>
                      Ship to a friend or other 3rd party
                    </Typography>
                  }
                />
              </Grid>
              {values.isFriend && (
                <Grid item xxs={12} xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      margin="dense"
                      size="small"
                      label="Recipient's name"
                      id="attention"
                      variant="outlined"
                      name="attention"
                      value={values.attention || ""}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xxs={12} xs={12} sm={8}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Address"
                    id="addressLine1"
                    helperText="(required)"
                    name="addressLine1"
                    value={values.addressLine1 || ""}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Apt # / Suite"
                    id="addressLine2"
                    name="addressLine2"
                    value={values.addressLine2 || ""}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={7}>
                <FormControl fullWidth required>
                  <TextField
                    margin="dense"
                    size="small"
                    label="City"
                    id="city"
                    name="city"
                    helperText="(required)"
                    value={values.city || ""}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth required>
                  <TextField
                    margin="dense"
                    size="small"
                    select
                    className={
                      regionSetting === "other" ? classes.hide : classes.show
                    }
                    label={
                      regionSetting === "USA"
                        ? "State"
                        : regionSetting === "Canada"
                        ? "Province"
                        : "State, Province or Zone"
                    }
                    id="zone"
                    name="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={values.zone}
                    onChange={handleChange}
                    SelectProps={{ native: true }}
                  >
                    <option></option>
                    {regionSetting === "USA"
                      ? usaStates.map((us, index) => (
                          <option key={index} value={us.abbreviation}>
                            {us.name}
                          </option>
                        ))
                      : regionSetting === "Canada"
                      ? canadaProvinces.map((ca, index) => (
                          <option key={index} value={ca.abbreviation}>
                            {ca.name}
                          </option>
                        ))
                      : ""}
                  </TextField>

                  <TextField
                    margin="dense"
                    size="small"
                    label="State, Province or Zone"
                    id="zone"
                    name="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    className={
                      regionSetting === "other" ? classes.show : classes.hide
                    }
                    value={values.zone}
                    onChange={handleChange}
                    SelectProps={{ native: true }}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={5} xs={5} sm={5}>
                <FormControl>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Postal Code"
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={values.postalCode || ""}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={7} xs={7} sm={7}>
                <MuiPhoneNumber
                  required
                  label="Phone Number"
                  id="phone"
                  value="phone"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  defaultCountry="us"
                  regions={["america", "europe", "asia"]}
                  disableAreaCodes
                  onChange={handlePhoneChange}
                />
                {/* <NoSsr>
                  <MuiPhoneInput
                    margin="dense"
                    size="small"
                    label="Phone Number"
                    id="phone"
                    value="phone"
                    value={values.phone}
                    name="phone"
                    fullWidth
                    variant="outlined"
                    defaultCountry="us"
                    regions={["america", "europe", "asia"]}
                    disableAreaCodes
                    onChange={handlePhoneChange}
                  />
                </NoSsr> */}
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAddress}
                      onChange={handleChangeAddressType}
                      name="billing"
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={classes.option}>
                      Use the same address for billing
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <ButtonBase
                  className={clsx(classes.bigButton, classes.contrastButton)}
                  type="submit"
                  disabled={warning.length > 3}
                >
                  <Typography
                    component="h1"
                    className={classes.boxTitle}
                    color="inherit"
                    gutterBottom
                  >
                    Next
                  </Typography>
                </ButtonBase>
                {warning.length > 3 && (
                  <Alert onClose={() => setWarning("")} severity="warning">
                    {warning}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 1,
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  mainCard: {
    width: "100%",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  helperText: {
    fontSize: theme.typography.pxToRem(13),
  },
  content: {
    padding: theme.spacing(2),
  },
  option: {
    fontSize: "14px",
    fontWeight: "light",
  },
  hide: {
    display: "none",
  },
  show: {},
  link: {
    textDecoration: "none",
    color: theme.palette.link,
    fontWeight: "bold",
  },
  bigButton: {
    width: "100%",
    border: "2px solid #000000",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  outlinedButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    "&:hover": {
      border: "2px solid #2d2d2d",
      color: theme.palette.common.almostBlack,
    },
  },
  contrastButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
      border: "2px solid #2d2d2d",
    },
  },
})

const START_GUEST_ORDER_MUTATION = gql`
  mutation(
    $sbuId: Int
    $cartId: String
    $firstName: String
    $lastName: String
    $emailAddress: String
    $phoneNumber: String
    $isOrg: Boolean
    $regionId: Int
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
    $thirdPartyAddress: Boolean
    $attention: String
    $addressCategoryId: Int
    $phoneTypeId: Int
  ) {
    startGuestOrder(
      sbuId: $sbuId
      cartId: $cartId
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
      isOrg: $isOrg
      regionId: $regionId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      zone: $zone
      city: $city
      postalCode: $postalCode
      thirdPartyAddress: $thirdPartyAddress
      attention: $attention
      addressCategoryId: $addressCategoryId
      phoneTypeId: $phoneTypeId
    ) {
      shippingAddressId

      person {
        uid
        firstName
        lastName
      }
    }
  }
`
export default withStyles(styles)(GuestInfo)
