import React from "react"
import { Container, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Layout from "../components/Layout/layout"

import GuestCheckout from "../components/Guest/GuestCheckout"
import CheckoutProvider from "../components/User/UserCart/Checkout/context/CheckoutProvider"

const Guest = ({ classes, children }) => {
  return (
    <Layout>
      <Container>
        <CheckoutProvider>
          <GuestCheckout />
        </CheckoutProvider>
      </Container>
    </Layout>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(Guest)
