import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import clsx from "clsx"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  NoSsr,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"

import {
  CreditCard as CreditCardIcon,
  Truck as ShippingIcon,
} from "react-feather"

import BigBlackButton from "../../DropIn/BigBlackButton"

import {
  regions,
  allRegions,
  usaStates,
  canadaProvinces,
} from "../../DropIn/Lists/geography"

import CheckoutContext from "../../User/UserCart/Checkout/context/CheckoutContext"

import GuestPaymentForm from "./GuestPaymentForm/index"
import { useAddressForm } from "../../utils/hooks/useAddressForm"

const addressTypes = [
  { id: 1, name: "Billing" },
  { id: 2, name: "Shipping" },
  { id: 3, name: "Billing and Shipping" },
]

const GuestPayment = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const billingAddress = state.guestBillingAddress
  const shippingAddressId = state.shippingAddressId
  const {
    values,
    setValues,
    handleChange,
    handleCheck,
    handleSubmit,
    updated,
  } = useAddressForm(setAddress)
  const editMode = state.editMode

  const [addressTypeId, setAddressTypeId] = useState(1)

  function setAddress() {
    dispatch({ type: "SET_GUEST_BILLING_ADDRESS", payload: values })
    // handleNextStep()
  }

  // const [region, setRegion] = useState(1)
  const [regionSetting, setRegionSetting] = useState("USA")
  const [findMoreRegions, setFindMoreRegions] = useState(false)
  const [billingAndShipping, setBillingAndShipping] = useState(true)

  const cart = JSON.parse(localStorage.getItem("ie-cart"))
  const cartId = cart.id

  const [submitting, setSubmitting] = useState(false)

  // const handleNextStep = () => {
  //   dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
  // }
  useEffect(() => {
    if (billingAddress) {
      setValues({
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        isOrg: billingAddress.isOrg,
        regionId: billingAddress.regionId,
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        addressLine3: billingAddress.addressLine3,
        zone: billingAddress.zone,
        city: billingAddress.city,
        postalCode: billingAddress.postalCode,
        attention: billingAddress.attention,
        isFriend: billingAddress.isFriend,
      })
    }
  }, [])
  useEffect(() => {
    setRegionSetting("USA")
    if (values.isInternational) {
      if (values.region == 3) setFindMoreRegions(true)
      values.region == 1
        ? setRegionSetting("USA")
        : values.region == 2
        ? setRegionSetting("Canada")
        : setRegionSetting("other")
    }
  }, [values.region])

  const handleChangeAddressType = event => {
    setBillingAndShipping(event.target.checked)
  }

  return (
    <div className={classes.root}>
      <Card square variant="outlined" className={classes.mainCard}>
        <CardHeader
          avatar={
            addressTypeId === 1 ? (
              <SvgIcon>
                <CreditCardIcon />
              </SvgIcon>
            ) : (
              <SvgIcon>
                <ShippingIcon />
              </SvgIcon>
            )
          }
          title={
            <Typography className={classes.title}>Payment Details</Typography>
          }
        />

        <Box className={classes.content}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xxs={12} xs={12}>
                <Typography className={classes.title}>
                  Name on the card
                </Typography>
              </Grid>

              <Grid item xxs={12} xs={12} sm={6}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    helperText="(required)"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    variant="outlined"
                    helperText="(required)"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={12} xs={12}>
                <Typography className={classes.title}>
                  Billing Address
                </Typography>
              </Grid>

              <Grid item xxs={12} xs={12} sm={6}>
                {/* <Typography>Ship To</Typography> */}

                <FormControlLabel
                  className={classes.option}
                  control={
                    <Checkbox
                      checked={values.internationalAddress}
                      onChange={handleCheck}
                      name="isInternational"
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={classes.option}>
                      Billing address is outside the USA
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xxs={12} xs={12} sm={6}>
                {values.isInternational && (
                  <FormControl fullWidth>
                    <TextField
                      margin="dense"
                      size="small"
                      select
                      variant="outlined"
                      className={findMoreRegions ? classes.hide : classes.show}
                      helperText="(required)"
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                    >
                      {regions.map(r => (
                        <option key={r.id} value={r.id}>
                          {r.name}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      margin="dense"
                      size="small"
                      fullWidth
                      select
                      label="More Regions"
                      className={findMoreRegions ? classes.show : classes.hide}
                      helperText="(required)"
                      value={values.region}
                      name="region"
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {allRegions.map(r => (
                        <option key={r.id} value={r.id}>
                          {r.name}
                        </option>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              </Grid>

              {values.isOrg && (
                <Grid item xxs={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      margin="dense"
                      size="small"
                      label="Organization"
                      id="org"
                      variant="outlined"
                      name="org"
                      value={values.org}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xxs={12} xs={12} sm={8}>
                <FormControl required fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Address"
                    id="addressLine1"
                    helperText="(required)"
                    name="addressLine1"
                    value={values.addressLine1}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Apt # / Suite"
                    id="addressLine2"
                    name="addressLine2"
                    value={values.addressLine2}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={7}>
                <FormControl fullWidth>
                  <TextField
                    margin="dense"
                    size="small"
                    label="City"
                    id="city"
                    name="city"
                    helperText="(required)"
                    value={values.city}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth required>
                  <TextField
                    margin="dense"
                    size="small"
                    select
                    className={
                      regionSetting === "other" ? classes.hide : classes.show
                    }
                    label={
                      regionSetting === "USA"
                        ? "State"
                        : regionSetting === "Canada"
                        ? "Province"
                        : "State, Province or Zone"
                    }
                    id="zone"
                    name="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={values.zone}
                    onChange={handleChange}
                    SelectProps={{ native: true }}
                  >
                    <option></option>
                    {regionSetting === "USA"
                      ? usaStates.map((us, index) => (
                          <option key={index} value={us.abbreviation}>
                            {us.name}
                          </option>
                        ))
                      : regionSetting === "Canada"
                      ? canadaProvinces.map((ca, index) => (
                          <option key={index} value={ca.abbreviation}>
                            {ca.name}
                          </option>
                        ))
                      : ""}
                  </TextField>

                  <TextField
                    margin="dense"
                    size="small"
                    label="State, Province or Zone"
                    id="zone"
                    name="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    className={
                      regionSetting === "other" ? classes.show : classes.hide
                    }
                    value={values.zone}
                    onChange={handleChange}
                    SelectProps={{ native: true }}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={12} xs={12} sm={6}>
                <FormControl>
                  <TextField
                    margin="dense"
                    size="small"
                    label="Postal Code"
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={values.postalCode}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                {updated ? (
                  ""
                ) : (
                  <ButtonBase
                    className={clsx(classes.bigButton, classes.contrastButton)}
                    type="submit"
                  >
                    <Typography
                      component="h1"
                      className={classes.boxTitle}
                      color="inherit"
                      gutterBottom
                    >
                      {editMode
                        ? "Update Billing Address"
                        : "Confirm Billing Address"}
                    </Typography>
                  </ButtonBase>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box style={{ marginBottom: "5px" }}>
          <Typography className={classes.title}>Card Details</Typography>
        </Box>
        {updated && <GuestPaymentForm />}
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 1,
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  mainCard: {
    width: "100%",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  helperText: {
    fontSize: theme.typography.pxToRem(13),
  },
  content: {
    padding: theme.spacing(2),
  },
  option: {
    fontSize: "14px",
    fontWeight: "light",
  },
  hide: {
    display: "none",
  },
  show: {},
  link: {
    textDecoration: "none",
    color: theme.palette.link,
    fontWeight: "bold",
  },
  bigButton: {
    width: "100%",
    border: "2px solid #000000",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  outlinedButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    "&:hover": {
      border: "2px solid #2d2d2d",
      color: theme.palette.common.almostBlack,
    },
  },
  contrastButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
      border: "2px solid #2d2d2d",
    },
  },
})

export default withStyles(styles)(GuestPayment)
