import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"

const GuestSummaryEmailPhone = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const email = state.guestEmail
  const phone = state.guestPhone
  return (
    <>
      <Typography className={classes.title}>
        {email || phone ? "Contact info" : ""}
      </Typography>

      {/* <Link to="/my/account" className={classes.link}> */}
      <Typography variant="caption" className={classes.info}>
        {email ? `Email: ${email}` : ""}
        <br />
      </Typography>
      {/* </Link>
      <Link to="/my/account" className={classes.link}> */}
      <Typography variant="caption" className={classes.info}>
        {phone ? `Phone: ${phone}` : ""}
      </Typography>
      {/* </Link> */}
    </>
  )
}

const styles = theme => ({
  root: {},
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  info: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
})

export default withStyles(styles)(GuestSummaryEmailPhone)
