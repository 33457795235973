import React, { useContext } from "react"
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

// import Loading from "../../../../Shared/Loading"
// import Error from "../../../../Shared/Error"
import { Box as OrdersIcon } from "react-feather"
import CheckoutContext from "../../../User/UserCart/Checkout/context/CheckoutContext"
import GuestOrderSummaryShipping from "./GuestOrderSummaryShipping"
import GuestOrderSummaryBilling from "./GuestOrderSummaryBilling"
import GuestOrderSummaryTotals from "./GuestOrderSummaryTotals"
import GuestSummaryOrderItemCard from "./GuestSummaryOrderItemCard"
import GuestOrderSummaryEmailPhone from "./GuestOrderSummaryEmailPhone"
import { useCart } from "../../../User/UserCart/Cart"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const GuestOrderSummary = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const order = state.order
  const shippingAddress = state.orderShipping
  const billingAddress = state.orderBilling
  const { items } = useCart()
  return (
    <>
      {/* <Paper square className={classes.paper}> */}
      <Card square variant="outlined" className={classes.mainCard}>
        <CardHeader
          avatar={
            <SvgIcon>
              <OrdersIcon />
            </SvgIcon>
          }
          title={
            <Typography className={classes.title}>Order Details</Typography>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {shippingAddress && <GuestOrderSummaryShipping />}
          </Grid>
          <Grid item xs={12} md={6}>
            {billingAddress && <GuestOrderSummaryBilling />}
          </Grid>

          <Grid item xs={12}>
            <GuestOrderSummaryEmailPhone />
          </Grid>
          <Grid item xs={12}>
            <GuestOrderSummaryTotals />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.title}>Order Items</Typography>

            {items &&
              items.map(item => (
                <Box mt={2} key={item.id}>
                  <GuestSummaryOrderItemCard item={item} items={items} />
                </Box>
              ))}
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Card>
    </>
  )
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
})
export default withStyles(styles)(GuestOrderSummary)
