import React, { useState, Fragment } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}
function isCharacterALetter(char) {
  return /[a-zA-Z]/.test(char)
}
const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const ConfirmationItemCard = ({ classes, lineItemQty, selectedItem }) => {
  const id = selectedItem.id
  const qty = selectedItem.qty
  const price = selectedItem.priceEach
  const itemTotal = qty * price
  // const selectedItem = selectedItem.item
  const isPrivate = selectedItem.item.isPrivate
  const thumbnail = selectedItem.item.itemImage
    ? selectedItem.item.itemImage[0].thumbnailUrl
    : null
  const itemCustomFields = JSON.parse(selectedItem.customFields)
  const customFields = []
  if (itemCustomFields) {
    for (const [key, value] of Object.entries(itemCustomFields)) {
      let format = (
        <Fragment key={key}>
          {isCharacterALetter(key.charAt(0))
            ? capitalize(
                key
                  .replace(/([a-z\d])([A-Z])/g, "$1" + " " + "$2")
                  .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + " " + "$2")
                  .toLowerCase()
              )
            : key}
          : {value}
          <br />
        </Fragment>
      )
      customFields.push(format)
    }
  }
  return (
    <>
      <Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={3} sm={2}>
            <Avatar
              variant="rounded"
              src={thumbnail}
              className={classes.thumbnail}
            />
          </Grid>
          <Grid item xs={9} sm={6}>
            <Box>
              {isPrivate ? (
                <Link
                  style={{ textDecoration: "none" }}
                  className={classes.link}
                  variant="body2"
                  to={`/my/items/${selectedItem.item.id}`}
                  state={{ id: `${selectedItem.item.id}` }}
                >
                  <Typography>{selectedItem.item.name}</Typography>
                </Link>
              ) : (
                <Typography>{selectedItem.item.name}</Typography>
              )}
              <Typography>{selectedItem.item.category.name}</Typography>
              {isPrivate && (
                <Typography variant="caption">Custom Private Item</Typography>
              )}
              <Typography>{customFields ? customFields : ""}</Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={8}>
              {qty} pcs
            </Grid>
            <Grid item xs={4}>
              ${financial(price)} ea
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={8}>
              Item Total
            </Grid>
            <Grid item xs={4}>
              <strong>${financial(itemTotal)}</strong>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {lineItemQty > 1 && <Divider />}
    </>
  )
}

const styles = theme => ({
  root: {},
  thumbnail: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})
export default withStyles(styles)(ConfirmationItemCard)
