import React, { useState, useEffect, useContext } from "react"

import { useCart } from "../../User/UserCart/Cart"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Tab,
  Tabs,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import Layout from "../../Layout/layout"

// import { useSiteMetadata } from "../components/utils/hooks/useSiteMetadata"
import CheckoutContext from "../../User/UserCart/Checkout/context/CheckoutContext"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import AddressFormComponent from "../../User/UserCart/Checkout/AddressForm/AddressFormComponent/index"
import GuestEditOrder from "./GuestEditOrder"
import GuestInfo from "./GuestInfo"
import GuestOrderSummary from "./GuestOrderSummary"
import GuestOrderConfirmation from "./GuestOrderConfirmation"
import GuestOrderCreateAccount from "./GuestOrderConfirmation/GuestOrderCreateAccount"
import GuestPayment from "./GuestPayment"
import Header from "../../User/UserCart/Checkout/Header"
import {
  PlusCircle as PlusCircleIcon,
  Truck as ShippingIcon,
  ShoppingCart as ShoppingCartIcon,
  DollarSign as PayIcon,
  CheckCircle as ConfirmIcon,
} from "react-feather"
import { navigate } from "gatsby"

const GuestCheckout = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const activeStep = state.activeStep
  const [currentTab, setCurrentTab] = useState("shipping")
  const [userStatus, setUserStatus] = useState("anonymous")
  const [loginRequired, setLoginRequired] = useState([])
  const [checkoutSignIn, setCheckoutSignIn] = useState(false)
  const [cartId, setCartId] = useState("")
  const [items, setItems] = useState("")
  const [started, setStarted] = useState(false)

  const isBrowser = () => typeof window !== "undefined"
  // const [checkoutSignIn, setCheckoutSignIn] = useState(true)
  // const isLoggedIn = state.isLoggedIn
  const [cart, setCart] = useState("")
  useEffect(() => {
    isBrowser && setCart(JSON.parse(window.localStorage.getItem("ie-cart")))
  }, [])
  useEffect(() => {
    if (cart) {
      let itemsFromStorage = JSON.stringify(cart.items)
      setCartId(cart.id)
      setStarted(cart.started)
      setItems(itemsFromStorage)
    }
  }, [cart])

  // const cartId = cart.id
  // const items = JSON.stringify(cart.items)
  // const started = cart.started

  const tabs = [
    { value: "cart", label: "Cart", icon: <ShoppingCartIcon /> },
    { value: "shipping", label: "Shipping", icon: <ShippingIcon /> },
    { value: "payment", label: "Pay", icon: <PayIcon /> },
    { value: "confirm", label: "Confirm", icon: <ConfirmIcon /> },
  ]

  useEffect(() => {
    if (activeStep === 1) setCurrentTab("shipping")
    if (activeStep === 2) setCurrentTab("payment")
    if (activeStep === 4) setCurrentTab("confirm")
  }, [activeStep])

  const handleTabsChange = (event, value) => {
    if (value === "cart") navigate("/cart")
    if (value === "shipping") {
      dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "shipping" })
      dispatch({ type: "SET_ACTIVE_STEP", payload: 1 })
      if (!state.showAddressComponent)
        dispatch({ type: "TOGGLE_ADDRESS_COMPONENT" })
    }
    if (value === "billing") {
      dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "billing" })
      dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
    }
    if (value === "confirm") {
      dispatch({ type: "SET_ACTIVE_STEP", payload: 4 })
      setCurrentTab("confirm")
    }
  }

  const getStep = activeStep => {
    switch (activeStep) {
      case 0:
        return <GuestEditOrder />
      case 1:
        return <GuestInfo />
      case 2:
        return <GuestPayment />
      case 4:
        return <GuestOrderConfirmation />
      default:
        return "unknown step"
      // case 0:
      //   return <EditOrder />
      // case 1:
      //   return <AddressForm />
      // case 2:
      //   return <PaymentForm />
      // case 3:
      //   return <ConfirmAndComplete />
      // case 4:
      //   return <SuccessPage />
      // default:
      //   return "unknown step"
    }
  }
  return (
    <div className={classes.root}>
      <Box mt={2} mb={2}>
        <Header />
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="secondary"
          className={classes.tabs}
        >
          {tabs.map(tab => (
            <Tab
              icon={<SvgIcon>{tab.icon}</SvgIcon>}
              // label={tab.label}
              aria-label={tab.label}
              key={tab.value}
              value={tab.value}
              className={classes.tab}
            />
          ))}
        </Tabs>
        <Grid container spacing={3} direction="row">
          {activeStep === 4 ? (
            <>
              <GuestOrderConfirmation />
              {/* <Grid item xxs={12} xs={12} sm={12} md={6}>
                <GuestOrderConfirmation />
              </Grid>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <GuestOrderCreateAccount orderId={orderId}/>
              </Grid> */}
            </>
          ) : (
            <>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                {getStep(activeStep)}
              </Grid>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <GuestOrderSummary />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 1,
  },
})

export default withStyles(styles)(GuestCheckout)
