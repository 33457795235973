import React, { useState, useContext } from "react"
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
import Loading from "../utils/Loading"
import Error from "../utils/Error"
import { navigate } from "gatsby-link"
import CheckoutContext from "../User/UserCart/Checkout/context/CheckoutContext"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

// const SignUpMessage = () => {
//   let message = (
//     <div>
//       <Typography gutterBottom component="h3">
//         Thanks for signing up!
//       </Typography>
//       <Typography component="body1">
//         We just sent you a confirmation email. Before accessing your account,
//         please follow the link in the email message so we can confirm that the
//         email login address belongs to you.
//       </Typography>
//     </div>
//   )
//   return message
// }

const SignUpGuest = ({ signedUp, setSignedUp, classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const orderId = parseInt(state.orderId)
  const [email, setEmail] = useState("")
  const [emailSelected, setEmailSelected] = useState(false)
  const [uid, setUid] = useState("")
  const [prId, setPrId] = useState("")
  const [conversionToken, setConversionToken] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [warning, setWarning] = useState("")
  // const [isGuest, setIsGuest] = useState(true)

  const { sbuId } = useSiteMetadata()

  const { data, loading, error } = useQuery(GUEST_CONVERSION_QUERY, {
    variables: { orderId: state.orderId },
    onCompleted: data => {
      console.log(data)
      const guestData = data.guestConversionData
      setConversionToken(guestData.conversionToken)
      setPrId(guestData.prId)
      setEmail(guestData.preregEmail)
    },
  })

  const [handleSubmit] = useMutation(SIGNUP_MUTATION, {
    variables: {
      preregToken: conversionToken,
      preregId: prId,
      email: email,
      password: password1,
      businessUnitId: sbuId,
    },
    onCompleted: data => {
      // dispatch({ type: "SET_LOGIN" })
      handleLogin()
    },
  })

  const [handleLogin] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password1,
    },
    onCompleted: data => {
      setSignedUp(true)
    },
  })
  const checkCharacters = () => {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      return true
    }
  }

  const validate = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }
  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {!signedUp ? (
          <form
            className={classes.form}
            noValidate
            onSubmit={event => {
              event.preventDefault()
              validate(event)
            }}
          >
            <Card variant="outlined" square>
              <Box className={classes.titleBox}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign Up Now
                </Typography>
                <Typography component="p" variant="body1">
                  Select and confirm a password to create your account
                </Typography>
              </Box>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ClickAwayListener
                      onClickAway={event => setEmailSelected(false)}
                    >
                      <TextField
                        fullWidth
                        label="Email"
                        helperText="Sign up with the email address you provided with your order."
                        value={email}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={event => setEmailSelected(true)}
                        variant="outlined"
                      />
                    </ClickAwayListener>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="password"
                      label="Password"
                      type="password"
                      helperText="Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
                      id="password1"
                      autoComplete="current-password"
                      value={password1}
                      onChange={event => setPassword1(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="password"
                      label="Confirm Password"
                      type="password"
                      id="password2"
                      autoComplete="current-password"
                      value={password2}
                      onChange={event => setPassword2(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <ButtonBase
                  className={classes.contrastButton}
                  type="submit"
                  disabled={loading || !password1.trim() || !password2.trim()}
                >
                  <Typography
                    component="h1"
                    className={classes.boxTitle}
                    color="inherit"
                    gutterBottom
                  >
                    {loading ? "Just one moment..." : "Sign Up"}
                  </Typography>
                </ButtonBase>
              </CardContent>
            </Card>
          </form>
        ) : (
          // <SignUpMessage />
          <div>
            <Typography gutterBottom component="h3">
              Thanks for signing up!
            </Typography>
            <Typography component="body1">
              We just sent you a confirmation email. Before accessing your
              account, please follow the link in the email message so we can
              confirm that the email login address belongs to you.
            </Typography>
          </div>
        )}
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: theme.palette.secondary.main,
  },
  formBox: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: theme.typography.pxToRem(17),
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  thanks: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  titleBox: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    alignItems: "center",
  },
  contrastButton: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
})

const GUEST_CONVERSION_QUERY = gql`
  query($orderId: Int!) {
    guestConversionData(orderId: $orderId) {
      conversionToken
      prId
      preregEmail
    }
  }
`
const SIGNUP_MUTATION = gql`
  mutation(
    $preregToken: String!
    $preregId: String!
    $email: String!
    $password: String!
    $businessUnitId: Int!
  ) {
    convertPreregToUser(
      preregToken: $preregToken
      preregId: $preregId
      email: $email
      password: $password
      businessUnitId: $businessUnitId
    ) {
      user {
        id
      }
      message
    }
  }
`
const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`
export default withStyles(styles)(SignUpGuest)
