import React, { useState } from "react"
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}
const ConfirmationTotals = ({ classes, calcs }) => {
  return (
    <>
      <Typography className={classes.title}>Order Summary</Typography>
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="spanning table"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">
                {calcs.subtotal ? `$${financial(calcs.subtotal)}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Postage</Typography>
              </TableCell>
              <TableCell align="right">
                {calcs.postageAmt ? `$${financial(calcs.postageAmt)}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Sales Tax</Typography>
                {/* {taxRate > 0 ? (
                      <Typography variant="caption">
                        {taxRate * 100}%
                      </Typography>
                    ) : (
                      ""
                    )} */}
              </TableCell>
              <TableCell align="right">
                {calcs.taxAmt ? `$${financial(calcs.taxAmt)}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align="right">
                {calcs.orderTotal ? `$${financial(calcs.orderTotal)}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paid</TableCell>
              <TableCell align="right">
                {calcs.orderPaymentTotal
                  ? `$${financial(calcs.orderPaymentTotal)}`
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Due</TableCell>
              <TableCell align="right">
                {calcs.balanceDue ? `$${financial(calcs.balanceDue)}` : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const styles = theme => ({
  root: {},
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})
export default withStyles(styles)(ConfirmationTotals)
